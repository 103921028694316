import { AxiosError, AxiosResponse } from "axios";
import { ServerError } from "../models";
import { APP_TOKEN_KEY, APP_USER_KEY } from "../../../utils";
import { checkAndParseResponse } from "../helpers";

export const onResponseFulfilled = (response: AxiosResponse): AxiosResponse =>
    response;

export const onResponseRejected = (error: AxiosError) => {
    // handle response error
    if (error.response) {
        // server responded with a status code outside the range of 2xx
        console.error("Response Error:", error.response.data);

        const { status, data } = error.response;
        const parsedData = checkAndParseResponse(data);
        const message =
            parsedData["message"] ||
            parsedData["hydra:description"] ||
            parsedData["detail"];

        // status code available
        if (status) {
            if (status === 401) {
                if (
                    message === "Invalid JWT Token" ||
                    message === "Expired JWT Token"
                ) {
                    // return refreshLoginToken(error);

                    // TODO:: temp solution not working
                    localStorage.removeItem(APP_TOKEN_KEY);
                    localStorage.removeItem(APP_USER_KEY);
                    // window.location.reload();
                }

                if (message === "Invalid credentials.") {
                    // some action
                }

                if (message === "JWT Token not found") {
                    // logOut();
                }

                return Promise.reject(new ServerError(message));
            }

            if (status === 403) {
                return Promise.reject(
                    new ServerError(
                        "Access denied: You are not authorized to perform this action.",
                    ),
                );
            }

            if (status === 404 || status === 422) {
                return Promise.reject(error);
            }

            if (status >= 500 && status <= 599) {
                return Promise.reject(new ServerError(message));
            }

            if (message) {
                return Promise.reject(new ServerError(message));
            }

            return Promise.reject(error);
        }
    } else if (error.request) {
        // request was made but no response was received
        console.error("Request Error:", error.request);

        return Promise.reject(error);
    } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error:", error.message);

        return Promise.reject(error);
    }

    return Promise.reject(error);
};
