import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { errorToast } from "../../../utils";
import { useAuthAction } from "../../../hooks";
import { User } from "../../../models";
import { AuthApi } from "../../../apis";

export const AutoLoginPage: FC = (): JSX.Element => {
    // hooks
    const { token } = useParams();
    const navigate = useNavigate();
    const { actionSetAuthToken, actionLogin, actionLogout } = useAuthAction();

    useEffect(() => {
        if (token && token.length > 0) {
            actionSetAuthToken(token);

            AuthApi.me<User>().then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                    actionLogout();
                } else if (response !== null) {
                    actionLogin(response);
                    navigate("/onboarding");
                }
            });
        }
    }, []);

    return <></>;
};
